export const postMessageToWindow = (data:any) => {
    if (window.self !== window.top) {
        window.parent.postMessage(data, "https://www.koctas.com.tr/");
    }
};

export const postMessageWithDataToWindow = (name:string,data:any) => {
    if (window.self !== window.top) {
        window.parent.postMessage({
            type: name,
            items: data 
        }, "https://www.koctas.com.tr/");
    }
};

