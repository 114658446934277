import {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Stack} from "@fluentui/react";
import {ErrorCircleRegular} from "@fluentui/react-icons";

import uuid from 'react-uuid';
import {isEmpty} from "lodash-es";

import styles from "./Chat.module.css";

import {
    ChatHistoryLoadingState,
    ChatMessage,
    ChatResponse,
    Conversation,
    conversationApi,
    ConversationRequest,
    ErrorMessage,
    historyUpdate,
    ToolMessageContent
} from "../../api";
import {Answer} from "../../components/Answer";
import {QuestionInput} from "../../components/QuestionInput";
import {AppStateContext} from "../../state/AppProvider";
import {useBoolean} from "@fluentui/react-hooks";
import {UserAnswer} from "../../components/UserAnswer/UserAnswer";
import {postMessageToWindow, postMessageWithDataToWindow} from "../../utils/postMessage";

const enum messageStatus {
    NotRunning = "Çalışmıyor",
    Processing = "İşleniyor",
    Done = "Tamamlandı"
}

const Chat = () => {
    const appStateContext = useContext(AppStateContext)
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [hideErrorDialog, {toggle: toggleErrorDialog}] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const date = new Date();
        const hour = date.getHours();
        const minute = date.getMinutes();
        setCurrentTime(`${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`);
    }, [messages]);

    useEffect(() => {
        if (processMessages == messageStatus.Done && messages.length > 0) {
            const last_assistant_object = messages.reverse().find(message => message.role !== undefined && message.role === "assistant");
            const last_assistant_message = last_assistant_object?.content
            if (last_assistant_message) {
                const matches = [...last_assistant_message.matchAll(/\*\*Ürün Adı:\*\* \[(.+?)\]\((.+?)\)/g)];
                if (matches.length > 0) {
                    matches.forEach(match => {
                        const link = match[2]; // İkinci yakalama grubu linki içerir
                        const productCodeMatch = link.match(/\/p\/(\d{10})/);
                        const item = {
                            id: productCodeMatch ? productCodeMatch[1] : '',
                            event: "view_assistant_item",
                            name: match[1]
                        };
                        postMessageWithDataToWindow('cbot_impression_item', item)
                    });
                }
            }
        }
    }, [processMessages])

    useEffect(() => {
        const handleAnchorClick = (event: any) => {
            if (event.target.tagName === 'A') {
                const link = event.target.href;
                const productCodeMatch = link.match(/p\/(\d{10})/);
                if (productCodeMatch) {
                    const productCode = productCodeMatch[1];
                    const data = {
                        id: productCode,
                        event: "select_assistant_item",
                        name: event.target.innerText
                    };
                    postMessageWithDataToWindow('cbot_click_item', data);
                }
            }
        };
        document.addEventListener('click', handleAnchorClick);
        return () => {
            document.removeEventListener('click', handleAnchorClick);
        };
    }, []);

    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"]

    useEffect(() => {
        setIsLoading(appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading)
    }, [appStateContext?.state.chatHistoryLoadingState])

    let assistantMessage = {} as ChatMessage
    let toolMessage = {} as ChatMessage
    let assistantContent = ""

    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {

        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent
        }

        if (resultMessage.role === TOOL) toolMessage = resultMessage

        isEmpty(toolMessage) ?
            setMessages([...messages, assistantMessage]) :
            setMessages([...messages, toolMessage, assistantMessage]);
    }

    const sendMessage = async (question: string, conversationId?: string) => {
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        let conversation = appStateContext?.state?.currentChat

        if (conversation) {
            setMessages([...messages, userMessage])
            conversation.messages.push(userMessage);
            appStateContext?.dispatch({type: 'UPDATE_CURRENT_CHAT', payload: conversation});
        }

        // Conversation id gelmediğinde ne olacak?
        const request: ConversationRequest = {
            message: question,
            conversation_id: conversationId ?? "",
            user_id: appStateContext?.state.currentChat?.userId!!
        };

        let result = {} as ChatResponse;
        let errorMessage = "Bir hata oluştu. Lütfen tekrar deneyin. Sorun devam ederse lütfen site yöneticisiyle iletişime geçin.";
        let errorChatMsg: ChatMessage = {
            id: uuid(),
            role: ERROR,
            content: errorMessage,
            date: new Date().toISOString()
        }
        try {
            const response = await conversationApi(request, abortController.signal);

            if(response.ok) {
                if (response?.body) {
                    const reader = response.body.getReader();
                    let runningText = "";

                    while (true) {
                        setProcessMessages(messageStatus.Processing)

                        const {done, value} = await reader.read();

                        if (done) break;

                        var text = new TextDecoder("utf-8").decode(value);
                        const objects = text.split("\n");

                        objects.forEach((obj) => {
                            try {
                                runningText += obj;
                                result = JSON.parse(runningText);

                                let newChatObj = {
                                    id: uuid(),
                                    date: new Date().toISOString(),
                                    content: result.toString(),
                                    role: ASSISTANT
                                }

                                conversation?.messages.push(newChatObj)
                                appStateContext?.dispatch({type: 'UPDATE_CURRENT_CHAT', payload: conversation!!});

                                runningText = "";
                            } catch {
                            }
                        });
                    }
                }
            } else {
                conversation?.messages.push(errorChatMsg);
                appStateContext?.dispatch({type: 'UPDATE_CURRENT_CHAT', payload: conversation!!});
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                if (result.error?.message) {
                    errorChatMsg.content = result.error.message;
                } else if (typeof result.error === "string") {
                    errorChatMsg.content = result.error;
                }
                conversation?.messages.push(errorChatMsg);
                appStateContext?.dispatch({type: 'UPDATE_CURRENT_CHAT', payload: conversation!!});
            } else {
                conversation?.messages.push(errorChatMsg);
                appStateContext?.dispatch({type: 'UPDATE_CURRENT_CHAT', payload: conversation!!});
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }
        return abortController.abort();
    };

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages)
        } else {
            setMessages([])
        }
    }, [appStateContext?.state.currentChat]);

    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({behavior: "smooth"})
    }, [showLoadingMessage, processMessages]);

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            } catch {
                return [];
            }
        }
        return [];
    }

    const handleCopy = (event: any) => {
        postMessageToWindow('cbot_container_copy')
    }

    return (
        <div className={styles.container} role="main">
            <Stack horizontal className={styles.chatRoot} onCopy={handleCopy}>
                <div className={styles.chatContainer}>
                    <div className={styles.chatMessageStream} style={{marginBottom: isLoading ? "40px" : "0px"}}
                         role="log">
                        {appStateContext?.state.currentChat?.messages.map((answer, index) => (
                            <>
                                {answer.role === "user" ? (
                                    <UserAnswer answer={answer.content}></UserAnswer>
                                ) : (
                                    answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                        <Answer
                                            answer={{
                                                answer: answer.content,
                                                citations: parseCitationFromMessage(messages[index - 1]),
                                            }}
                                            onCitationClicked={c => {
                                            }}
                                        />
                                    </div> : answer.role === ERROR ? <div className={styles.chatMessageError}>
                                        <Stack horizontal className={styles.chatMessageErrorContent}>
                                            <ErrorCircleRegular className={styles.errorIcon}
                                                                style={{color: "rgba(182, 52, 67, 1)"}}/>
                                            <span>Error</span>
                                        </Stack>
                                        <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                    </div> : null
                                )}
                            </>
                        ))}
                        {(showLoadingMessage || appStateContext?.state.newConversationLoadingState) && (
                            <>
                                <div className={styles.chatMessageGpt}>
                                    <Answer isLoading={true}
                                            answer={{
                                                answer: "",
                                                citations: []
                                            }}
                                            onCitationClicked={() => null}
                                    />
                                </div>
                            </>
                        )}
                        <div ref={chatMessageStreamEnd}/>
                    </div>
                    <Stack horizontal className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Lütfen mesajınızı bir paragraf ile anlatın..."
                            disabled={isLoading}
                            onSend={(question, id) => {
                                sendMessage(question, id)
                            }}
                            conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                        />
                    </Stack>
                </div>
            </Stack>
            )
        </div>
    );
};

export default Chat;
