import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import logo from "../../assets/logo.svg";
import { CommandBarButton, Dialog, Stack, TextField, ICommandBarStyles, IButtonStyles, DefaultButton  } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import {CloseButton, HistoryButton, RefreshButton, ShareButton} from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import {ChatHistoryLoadingState, newConversationApi} from "../../api";
import uuid from "react-uuid";

const Layout = () => {
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy URL");
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [isBouncing, setIsBouncing] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const appStateContext = useContext(AppStateContext)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userId = params.get('user_id');

    const refreshButtonClick = async () => {

        appStateContext?.dispatch({type: 'UPDATE_CURRENT_CHAT', payload: null});
        appStateContext?.dispatch({type: 'UPDATE_NEW_CONVERSATION_LOADING_STATE', payload: true});

        newConversationApi({
            user_id: userId
        })
            .then((res) => res.json())
            .then((res) => {
                appStateContext?.dispatch({type: 'UPDATE_NEW_CONVERSATION_LOADING_STATE', payload: false});
                appStateContext?.dispatch({type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Success});
                appStateContext?.dispatch({
                    type: 'UPDATE_CURRENT_CHAT', payload: {
                        id: res.conversation_id,
                        title: "Konuşma",
                        messages: [{
                            id: uuid(),
                            role: "assistant",
                            content: res.message,
                            date: new Date().toISOString()
                        }],
                        date: new Date().toISOString(),
                        userId: userId ? userId: res.user_id
                    }
                });
                const result = res.json();
            }).catch((err) => {
            appStateContext?.dispatch({type: 'UPDATE_NEW_CONVERSATION_LOADING_STATE', payload: false});
        })
    };

    useEffect(() => {
        const date = new Date();
        const hour = date.getHours();

        if (hour >= 19 || hour < 7) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, []);

    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied URL");
        }
    }, [copyClicked]);

    useEffect(() => {}, [appStateContext?.state.isCosmosDBAvailable.status]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsBouncing(true);
            setTimeout(() => {
                setIsBouncing(false);
            }, 2000);
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between"
                >
                    <Stack horizontal verticalAlign="center" className={styles.headerLeft}>
                        <img
                            src={logo}
                            height={105}
                            className={`${styles.headerIcon} ${isBouncing ? styles.bounce : ''}`}
                            aria-hidden="true"
                         alt={"logo"}/>
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}>KoçtaşGPT</h1>
                        </Link>
                    </Stack>
                    <Stack horizontal className={styles.headerRight} tokens={{ childrenGap: 3 }} >
                            <RefreshButton onClick={refreshButtonClick} disabled={clearingChat} className="refreshButton" text="Konuşmayı Yenile" />
                            <CloseButton className="closeButton"/>
                    </Stack>
                </Stack>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
