import { useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import SendActive from "../../assets/SendActive.svg";
import styles from "./QuestionInput.module.css";
import { postMessageToWindow } from "../../utils/postMessage";

interface Props {
    onSend: (question: string, id?: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, conversationId }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [isFocused, setIsFocused] = useState(false);
    const maxLength = 250;
    const [firstMessageSent, setFirstMessageSent] = useState<boolean>(false);


    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        if(conversationId){
            onSend(question, conversationId);
        }else{
            onSend(question);
        }

        if (!firstMessageSent) {
            postMessageToWindow('cbot_start_message');
            setFirstMessageSent(true);
        }

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue!.length <= maxLength) {
            setQuestion(newValue || "");
        }
    };

    const handleFocusChange = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, isTextFieldFocused: boolean) => {
        setIsFocused(isTextFieldFocused);
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <>
            <div className={styles.questionInputContainer}>
                <TextField
                    className={`${styles.questionInputTextArea} ${isFocused ? styles.questionInputTextAreaActive : ''}`}
                    placeholder={placeholder}
                    multiline
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    onFocus={(event) => handleFocusChange(event, true)}
                    onBlur={(event) => handleFocusChange(event, false)}
                />
                <span className={styles.questionInputCounterText}>{question.length}/{maxLength}</span>
            </div>
            <div className={`${styles.questionInputSendButtonContainer} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : styles.questionInputSendButton}`}
                 role="button"
                 tabIndex={0}
                 aria-label="Ask question button"
                 onClick={sendQuestion}
                 onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
            >
                { sendQuestionDisabled ?
                    (<img width={18} height={20} src={Send} /> ) :
                    (<img width={18} height={20} src={SendActive} />)
                }
                <span>Gönder</span>
            </div>
        </>
    );
};
