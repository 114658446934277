import { useEffect, useState } from "react";

import styles from "./UserAnswer.module.css";
import {Stack} from "@fluentui/react";

interface Props {
    answer: string;
}

export const UserAnswer = ({
    answer,
}: Props) => {
    const [currentTime, setCurrentTime] = useState('');
    useEffect(() => {
        const date = new Date();
        const hour = date.getHours();
        const minute = date.getMinutes();
        setCurrentTime(`${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`);
    }, []);

    return (
        <>
            <Stack className={styles.chatMessageUser}>
                <Stack className={styles.userAnswerContainer} tabIndex={0}>
                    <Stack.Item grow className={styles.userAnswerText}>
                        {answer}
                    </Stack.Item>
                    <Stack.Item className={styles.userAnswerFooter}>
                        <Stack.Item className={styles.userAnswerDisclamier}>
                            {currentTime}
                        </Stack.Item>
                    </Stack.Item>
                </Stack>

            </Stack>
        </>
    );
};
