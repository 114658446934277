import { CommandBarButton, DefaultButton, IButtonProps, IButtonStyles, ICommandBarStyles } from "@fluentui/react";

interface ShareButtonProps extends IButtonProps {
    onClick: () => void;
  }

export const ShareButton: React.FC<ShareButtonProps> = ({onClick}) => {
    const shareButtonStyles: ICommandBarStyles & IButtonStyles = {
        root: {
          width: 86,
          height: 32,
          borderRadius: 4,
          background: '#d75d2a',
        //   position: 'absolute',
        //   right: 20,
          padding: '5px 12px',
          marginRight: '20px'
        },
        icon: {
          color: '#FFFFFF',
        },
        rootHovered: {
          background: '#a75d2a',
        },
        label: {
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '20px',
          color: '#FFFFFF',
        },
      };

      return (
        <CommandBarButton
                styles={shareButtonStyles}
                iconProps={{ iconName: 'Share' }}
                onClick={onClick}
                text="Paylaş"
        />
      )
}

interface HistoryButtonProps extends IButtonProps {
    onClick: () => void;
    text: string;
  }

export const HistoryButton: React.FC<HistoryButtonProps> = ({onClick, text}) => {
    const historyButtonStyles: ICommandBarStyles & IButtonStyles = {
        root: {
            width: '180px',
            border: `1px solid #D1D1D1`,
          },
          rootHovered: {
            border: `1px solid #D1D1D1`,
          },
          rootPressed: {
            border: `1px solid #D1D1D1`,
          },
      };

      return (
        <DefaultButton
            text={text}
            iconProps={{ iconName: 'History' }}
            onClick={onClick}
            styles={historyButtonStyles}
        />
      )
}

interface RefreshButtonProps extends IButtonProps {
    onClick: () => void;
    text: string;
    className?: string
}


export const RefreshButton: React.FC<RefreshButtonProps> = ({onClick, text,className,disabled}) => {
    const refreshButtonStyles: ICommandBarStyles & IButtonStyles = {
        root: {
            width: '140px',
            height: '48px',
            backgroundColor : '#FFFFFF4D',
            color: 'var(--color-button-text)',
            border: 'none',
            borderRadius: '15px',
            fontWeight: '500',
            fontSize:'12px',
            minWidth: 'unset',
            padding: '0px 6px'
        },
        rootHovered: {
            width: '140px',
            height: '48px',
            backgroundColor : '#FFFFFF9D',
            color: 'var(--color-button-text)',
            border: 'none',
            borderRadius: '15px',
            minWidth: 'unset'
        },
        rootPressed: {
            width: '140px',
            height: '48px',
            backgroundColor : '#FFFFFF',
            color: 'var(--color-button-text)',
            border: 'none',
            borderRadius: '15px',
            minWidth: 'unset'
        },
    };

    const hideSpanStyles = `
        .refreshButton i {
            transform: rotate(90deg);
        }
        @media (max-width: 400px) {
            .refreshButton .ms-Button-textContainer {
                display: none !important;
            }
            .refreshButton  {
               width: 48px !important;
            }
        }
    `;


    return (
        <>
            <style>{hideSpanStyles}</style>
            <DefaultButton
                disabled={disabled}
                text={text}
                iconProps={{ iconName: 'Refresh' }}
                onClick={onClick}
                className={className}
                styles={refreshButtonStyles}
            />
        </>
    )
}


interface CloseButtonProps extends IButtonProps {
    className?: string;
}


export const CloseButton: React.FC<CloseButtonProps> = ({className}) => {
    const CloseButtonStyles: ICommandBarStyles & IButtonStyles = {
        root: {
            width: '48px',
            height: '48px',
            backgroundColor : '#FFFFFF4D',
            color: 'var(--color-close-button-text)',
            border: 'none',
            borderRadius: '15px',
            minWidth: 'unset'
        },
        rootHovered: {
            width: '48px',
            height: '48px',
            backgroundColor : '#FFFFFF9D',
            color: 'var(--color-close-button-text)',
            border: 'none',
            borderRadius: '15px',
            minWidth: 'unset'
        },
        rootPressed: {
            width: '48px',
            height: '48px',
            backgroundColor : '#FFFFFF',
            color: 'var(--color-close-button-text)',
            border: 'none',
            borderRadius: '15px',
            minWidth: 'unset'
        },
    };

    const sendMessageToParent = () => {
        const message = 'cbot_close_popup';
        if (window.self !== window.top) {
            window.parent.postMessage(message, "https://www.koctas.com.tr/");
        }
    };

    return (
        <DefaultButton
            iconProps={{ iconName: 'ChromeClose',  style: { fontSize: '10px', fontWeight: '800'} }}
            className={className}
            styles={CloseButtonStyles}
            onClick={()=> {sendMessageToParent()}}
        />
    )
}