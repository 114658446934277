import React, {createContext, useReducer, ReactNode, useEffect, useRef} from 'react';
import {appStateReducer} from './AppReducer';
import {
    ChatHistoryLoadingState,
    CosmosDBHealth,
    CosmosDBStatus,
    newConversationApi
} from '../api';
import {Conversation} from '../api';
import uuid from "react-uuid";

export interface AppState {
    newConversationLoadingState: boolean,
    isChatHistoryOpen: boolean;
    chatHistoryLoadingState: ChatHistoryLoadingState;
    isCosmosDBAvailable: CosmosDBHealth;
    chatHistory: Conversation[] | null;
    filteredChatHistory: Conversation[] | null;
    currentChat: Conversation | null;
}

export type Action =
    | { type: 'TOGGLE_CHAT_HISTORY' }
    | { type: 'UPDATE_NEW_CONVERSATION_LOADING_STATE', payload: boolean }
    | { type: 'SET_COSMOSDB_STATUS', payload: CosmosDBHealth }
    | { type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState }
    | { type: 'UPDATE_CURRENT_CHAT', payload: Conversation | null }
    | { type: 'UPDATE_FILTERED_CHAT_HISTORY', payload: Conversation[] | null }
    | { type: 'UPDATE_CHAT_HISTORY', payload: Conversation } // API Call
    | { type: 'UPDATE_CHAT_TITLE', payload: Conversation } // API Call
    | { type: 'DELETE_CHAT_ENTRY', payload: string } // API Call
    | { type: 'DELETE_CHAT_HISTORY' }  // API Call
    | { type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: string }  // API Call
    | { type: 'FETCH_CHAT_HISTORY', payload: Conversation[] | null }  // API Call

const initialState: AppState = {
    newConversationLoadingState: true,
    isChatHistoryOpen: false,
    chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
    chatHistory: null,
    filteredChatHistory: null,
    currentChat: null,
    isCosmosDBAvailable: {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured,
    }
};

export const AppStateContext = createContext<{
    state: AppState;
    dispatch: React.Dispatch<Action>;
} | undefined>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({children}) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState);
    const abortFuncs = useRef([] as AbortController[]);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userId = params.get('user_id');


    useEffect(() => {
        const startNewChart = async () => {

            dispatch({type: 'UPDATE_NEW_CONVERSATION_LOADING_STATE', payload: true});

            newConversationApi({
                user_id: userId
            })
                .then((res) => res.json())
                .then((res) => {
                    dispatch({type: 'UPDATE_NEW_CONVERSATION_LOADING_STATE', payload: false});
                    dispatch({type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Success});
                    dispatch({
                        type: 'UPDATE_CURRENT_CHAT', payload: {
                            id: res.conversation_id,
                            title: "Konuşma",
                            messages: [{
                                id: uuid(),
                                role: "assistant",
                                content: res.message,
                                date: new Date().toISOString()
                            }],
                            date: new Date().toISOString(),
                            userId: res.user_id
                        }
                    });
                    const result = res.json();
                }).catch((err) => {
                dispatch({type: 'UPDATE_NEW_CONVERSATION_LOADING_STATE', payload: false});
            })
        }

        startNewChart().then();

    }, []);


    return (
        <AppStateContext.Provider value={{state, dispatch}}>
            {children}
        </AppStateContext.Provider>
    );
};


